import { Link } from 'gatsby';
import React, { useState } from 'react';
import Logo from '../logo/Logo';
import NavBar from '../navbar/NavBar';
import NavItem from '../navitem/NavItem';
import styles from './HeaderMobile.module.scss';

type Props = {
  siteTitle: string;
};

const HeaderMobile = ({ siteTitle }: Props) => {
  const [state, setState] = useState(true);

  function toggle() {
    setState(!state);
  }

  return (
    <header className={styles.header}>
      <Link to="/">
        <Logo key={siteTitle} />
      </Link>
      <div className={`${styles.hamburger}`} onClick={toggle}>
        {state ? (
          <div>
            <div className={styles.hamburgerLines} />
            <div className={styles.hamburgerLines} />
            <div className={styles.hamburgerLines} />
          </div>
        ) : (
          <Modal />
        )}
      </div>
    </header>
  );
};

export default HeaderMobile;

const Close = () => {
  const [state, setState] = useState(true);

  function toggle() {
    setState(!state);
  }

  return (
    <div className={styles.close} onClick={toggle}>
      <div />
      <div />
      <div />
    </div>
  );
};

const Modal = () => {
  return (
    <div className={styles.modal}>
      <div className={styles.header}>
        <Link to="/">
          <Logo key={''} logoFill={'white'} />
        </Link>
        <Close />
      </div>
      <NavBar>
        <NavItem label={'Services'} link={'services'}></NavItem>
        <NavItem label={'Industries'} link={'industries'}></NavItem>
        <NavItem label={'Experience'} link={'experience'}></NavItem>
        <NavItem label={'About us'} link={'about-us'}></NavItem>
        <NavItem label={'Contact us'} link={'contact'}></NavItem>
      </NavBar>
    </div>
  );
};
