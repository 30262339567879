import React from 'react'
import styles from './NavBar.module.scss'

type Props = {
  children: any;
}

const NavBar = ({children}: Props) => <nav className={styles.navbar}>{children}</nav>

export default NavBar
