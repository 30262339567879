import { Link } from 'gatsby';
import React from 'react';
import Logo from '../logo/Logo';
import NavBar from '../navbar/NavBar';
import NavItem from '../navitem/NavItem';
import styles from './Header.module.scss';

type Props = {
  siteTitle: string;
};

const Header = ({ siteTitle }: Props) => (
  <header className={styles.header}>
    <Link to="/">
      <Logo key={siteTitle} />
    </Link>

    <NavBar>
      <NavItem label={'Services'} link={'services'}></NavItem>
      <NavItem label={'Industries'} link={'industries'}></NavItem>
      <NavItem label={'Experience'} link={'experience'}></NavItem>
      <NavItem label={'About us'} link={'about-us'}></NavItem>
      <NavItem label={'Contact us'} link={'contact'}></NavItem>
    </NavBar>
  </header>
);

export default Header;
