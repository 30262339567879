import React from 'react'
import {Link} from 'gatsby'
import styles from './NavItem.module.scss'

const NavBarItem = ({label, link}: INavBarItem) => (
  <React.Fragment>
    <Link to={`/${link}`} className={styles.item} activeClassName={styles.active}>
      <span>{label}</span>
    </Link>
  </React.Fragment>
)

export default NavBarItem
