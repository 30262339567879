import React from 'react';
import { Link } from 'gatsby';
import Logo from '../logo/Logo';
import styles from './Footer.module.scss';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.footerItem}>
          <div className={styles.logoContainer}>
            <Logo logoFill={'white'} />
          </div>
          <div className={styles.info}>
            Phone:{' '}
            <a className={styles.footerLink} href={`tel:+4791171787`} title="phonenumber">
              (+47) 91 17 17 87
            </a>
          </div>
          <div className={styles.info}>
            Email:{' '}
            <a className={styles.footerLink} href={`mailto:mail@hyexsafe.com`} title="email">
              mail@hyexsafe.com
            </a>
          </div>
          <div className={styles.info}>
            <a
              className={styles.footerLink}
              title="LinkedIn"
              href={'https://www.linkedin.com/company/hyexsafety/about/'}
            >
              LinkedIn
            </a>
          </div>
          <div className={styles.info}>
            <Link className={styles.footerLink} to={'/browser-cookies'}>
              Browser cookies
            </Link>
          </div>
        </div>

        <div className={styles.footerItem2}>
          <p className={styles.footerHeadlineLinks}>
            <Link to={`/services`} className={styles.footerLink}>
              Services
            </Link>
          </p>
          <p className={styles.footerHeadlineLinks}>
            <Link to={`/industries`} className={styles.footerLink}>
              Industries
            </Link>
          </p>
          <p className={styles.footerHeadlineLinks}>
            <Link to={`/about-us`} className={styles.footerLink}>
              About
            </Link>
          </p>
          <p className={styles.footerHeadlineLinks}>
            <Link to={`/contact`} className={styles.footerLink}>
              Contact us
            </Link>
          </p>
          <p className={styles.footerHeadlineLinks}>
            <Link to={`/news`} className={styles.footerLink}>
              News
            </Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
