import React, { useEffect, useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import { StaticQuery, graphql, Link } from 'gatsby';
import Header from '../header/Header';
import HeaderMobile from '../headermobile/HeaderMobile';
import Footer from '../footer/Footer';

type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  const defaultWindowWidth = 1024;
  const [windowSize, setWindowSize] = useState(defaultWindowWidth);

  useEffect(() => {
    const setWindowWidth = () => {
      if (typeof window !== undefined) {
        setWindowSize(window.innerWidth);
      }
    };

    const handleResize = () => {
      setWindowWidth();
    };

    if (typeof window !== undefined) {
      window.addEventListener('resize', handleResize);
    }

    setWindowWidth();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          {windowSize < defaultWindowWidth ? (
            <HeaderMobile siteTitle={data.site.siteMetadata.title} />
          ) : (
            <Header siteTitle={data.site.siteMetadata.title} />
          )}
          <main>{children}</main>
          <CookieConsent
            location="bottom"
            buttonText="X"
            cookieName="myAwesomeCookieName2"
            style={{
              background: '#E1E2E3',
              padding: '15px',
              display: 'flex',
              flexWrap: 'wrap-reverse',
            }}
            buttonStyle={{
              border: '1px solid black',
              fontSize: '18px',
              fontWeight: '600',
              background: '#E1E2E3',
              borderRadius: '20px',
              padding: '3px',
              width: '40px',
              height: '40px',
              textAlign: 'center',
            }}
            expires={150}
          >
            <span style={{ fontSize: '18px', color: 'black' }}>
              We use cookies to provide you with the best possible experience. By closing this
              message, you agree to our use of cookies. <br />
              You can learn more about cookies on our{' '}
              <Link to="/browser-cookies">Browser cookies page.</Link>
            </span>
          </CookieConsent>
          <Footer />
        </>
      )}
    />
  );
};

export default Layout;
